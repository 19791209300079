<template>
    <div class="zixun-left">
        <H_title left-txt="中医资讯" :type="type" :rightTxt="rightTxt" url="/information"></H_title>
        <div class="zixun-left-cont">
            <div v-for="it in zixunList" :key="it.id" @click="toDetails(it.id)" class="zixun-left-list">
                <div class="zixun-left-list-img">
                    <el-image lazy style="width: 100%; height: 100%" :src="it.imgurl" fit="cover"></el-image>
                </div>
                <div class="zixun-left-list-right">
                    <div class="zixun-left-list-head txt1flow">
                        {{ it.title }}
                    </div>
                    <div class="zixun-left-list-txt">
                        {{ it.subtitle }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="paginationShow" class="pagination">
            <!-- <el-pagination background :pager-count="5" layout="prev, pager, next" :total="total">
            </el-pagination> -->
            <el-pagination :pager-count="5" :page-count="page" :page-size="listLength" background
            layout="total, prev, pager, next" :total="total" @size-change="handleCurrentChange"
            @current-change="handleCurrentChange"  />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Zixun_information',
    props: {
        listLength: {
            default: 3,
            type: Number
        },
        paginationShow: {
            default: false,
            type: Boolean
        },
        rightTxt: {
            default: '更多>',
            type: String
        },
        type: {
            default: 'bg',
            // default: 'txt',
            type: String
        }
    },
    data() {
        return {
            zixunList: [],
            total: 0,
            page:1,
        };
    },
    created() {
        this.init()
    },
    methods: {
        handleCurrentChange(e){
            this.page = e
            this.init()
        },
        toDetails(i) {
            this.$router.push({
                path: '/information_details?id=' + i,
            })
        },
        init() {
            this.$api.getarticlelist({
                page: this.page,
                limit: this.listLength
            }).then(res => {
                // console.log(res);
                if (res.statusCode == 200) {
                    this.zixunList = res.data.list
                    this.total = res.data.pagination?.total
                }
            }).catch(err => {
                console.log(err);
            })
        },
    }

}
</script>
<style lang="scss" scoped>
.zixun-left {
    width: 100%;
    border: 1px solid #ddd;

    .zixun-left-cont {
        height: calc(100% - 41px);
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .zixun-left-list {
            cursor: pointer;
            display: flex;
            margin-bottom: 10px;

            &:hover {
                background-color: #e9e9e9;
            }

            .zixun-left-list-img {
                width: 140px;
                height: 80px;
                background-color: aqua;
            }

            .zixun-left-list-right {
                flex: 1;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                overflow: hidden;

                // .zixun-left-list-head {
                //     font-size: 18px;
                // }

                .zixun-left-list-txt {
                    overflow: hidden;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    font-size: 14px;
                    color: #727272;
                }
            }
        }
    }
}
</style>