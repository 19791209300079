<template>
    <div :class="type=='txt'?'title':'title2'">
        <h1 class="title-txt">
            {{ leftTxt }}
        </h1>
        <div v-if="rightTxt !== 'no'" @click="navTo()" class="title-more">
            {{ rightTxt }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'H_title',
    props: {
        leftTxt: {
            default: '',
            type: String
        },
        rightTxt: {
            default: '更多>',
            type: String,
        },
        type: {
            // default: 'bg',
            default: 'txt',
            type: String
        },
        url: {
            default: '/index',
            type: String
        }
    },
    data() {
        return {

        }
    },
    methods: {
        navTo() {
            this.$router.push({
                path: this.url,
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #98181c;
    margin-bottom: 8px;

    .title-txt {
        color: #98181c;
        font-size: 24px;
        font-weight: bold;
    }

    .title-more {
        cursor: pointer;

        &:hover {
            color: #98181c;
        }
    }
}

.title2 {
    width: 100%;
    padding: 10px 20px;
    background-color: #98181c;
    display: flex;
    justify-content: space-between;
    color: #fff;

    .title-txt {
        font-weight: bold;
        font-size: 16px;
    }

    .title-more {
        cursor: pointer;

        &:hover {
            color: #d4d4d4;
        }
    }
}
</style>