import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'

Vue.use(ElementUI);

// 全局组件
import M_title from '@/components/m_title'
Vue.component('M_title', M_title)
import H_title from '@/components/pc_title'
Vue.component('H_title', H_title)

import * as Api from '@/utils/api'
Vue.prototype.$api = Api

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
