<template>
    <div class="PC-nav">
        <div class="pc-nav ">
            <div class="logo">
                <img alt="Vue logo" src="@/assets/logo.png">
            </div>
            <div class="nav-const-list" v-for="it in navList" :key="it.id">
                <!-- <p @click="navTo(it.url)" class="nav-const-list-head">{{it.name}}<i v-if="it.child" class="el-icon-arrow-down"></i></p> -->
                <router-link :to="it.url" class="nav-const-list-head">{{it.name}}</router-link>
                <div v-if="it.child" class="nav-const-list-chird">
                    <span @click="navTo(item.url)" v-for="(item,index) in it.child" :key="index">{{ item.name }}</span>
                </div>
            </div>
            <div class="nav-input">
                <input type="text" v-model="serlectdocValue" placeholder="🔍查找专家">
                <i class="el-icon-search" @click="serlectdoc()"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Pc_nav',
    data() {
        return {
            serlectdocValue:'',
            navList: [{
              id:0,
              name:'首页',
              url:'/index'  
            },{
              id:1,
              name:'中医资讯',
              url:'/information'
            },{
              id:2,
              name:'专家团队',
              url:'/doctor'  
            },{
              id:3,
              name:'找科室',
              url:'/administrative'  
            },{
              id:4,
              name:'看诊服务',
              url:'/from' ,
              child:[{
                name:'咨询预约',
                url:'/from'
              }]
            },{
              id:5,
              name:'了解守和堂',
              url:'/about'  
            },],
        };
    },
    methods:{
        navTo(url){
            this.$router.push({
                path: url,
                
            })
        },
        serlectdoc(){
            // this.serlectdocValue
            this.$router.push({
                path: `/doctor?doc=${this.serlectdocValue}`,
            })
        }
    }

}
</script>
<style lang="scss" scoped>
$color:#98181c;
$navHeight:70px;
.linkActive{
    color: $color !important;
    font-weight: bold;
    text-shadow: 4px 4px 5px #a9a9a9;
}
.PC-nav{
    width: 100%;
    background: url(@/assets/navBg.jpg) no-repeat top center;
    background-size: 100% 100%;
    position: -webkit-sticky;
    position: sticky;
    z-index: 9;
    top: 0px;
    box-shadow: 0px 5px 14px #969696b4;
}
.pc-nav {
    width: 1270px;
    height: $navHeight;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        width: 160px;
        height: 40px;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    .nav-const-list {
        position: relative;
        padding: 0 10px;

        .nav-const-list-head {
            height: $navHeight;
            line-height: $navHeight;
            height: 100%;
            cursor: pointer;
            text-decoration: none;
            color: #000;

            &:hover {
                color: $color;
            }
        }

        .nav-const-list-chird {
            width: 150px;
            display: none;
            flex-direction: column;
            align-items: center;
            position: absolute;
            z-index: 999999999;
            top: $navHeight;
            left: 50%;
            transform: translateX(-50%);
            border-top: 3px solid $color;
            box-shadow: 2px 3px 13px #b1b1b1ad;
            padding: 10px;
            border-radius: 0 0 10px 10px;
            line-height: 30px;
            background-color: rgb(255, 249, 241);

            >span {
                cursor: pointer;

                &:hover {
                    color: $color;
                }
            }
        }

        &:hover .nav-const-list-chird {
            display: flex;
        }
    }

    .nav-input {
        border: 2px solid $color;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 3px 5px;

        >input {
            height: 25px;
            width: 200px;
            border: none;
            background: none;
            outline: none;
        }

        >i {
            color: $color;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
        }
    }
}
</style>