/**
 * @author 2534894958
 * @description 路由定义
 */

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const pushPlugin = Router.prototype.push
Router.prototype.push = function (params) {
    pushPlugin.call(this, params).catch(() => { })
}

import M_index from '@/pages/m_index/m_index'
import Pc_index from '@/pages/pc_index/pc_index'
import NotFound from '../pages/sys/notFound'
import Zi_xun from '../pages/views/zi_xun'
import Doctor_view from '../pages/views/doctor_view'
import Doctor_details from '../pages/views/doctor_details'

function _isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return window.innerWidth <= 1270 || flag;
}

const router = new Router({
    linkActiveClass:'linkActive',
    routes: [
        { path: '/test', component: resolve => require(['@/pages/sys/views_layout'], resolve) },
        // 默认重定向
        { path: '/', redirect: '/index' },
        { path: '/index', component: _isMobile() ? M_index : Pc_index, meta: {type: 'pc'} },
        { path: '/403', component: NotFound, meta: {type: '404'} },
        { path: '*', component: NotFound, meta: {type: '404'} },
        
        { path: '/information', component: Zi_xun, meta:{name:'中医资讯'} },
        { path: '/information_details', component: resolve => require(['@/pages/views/information_details.vue'], resolve), meta:{name:'中医资讯'} },
        { path: '/doctor', component: Doctor_view, meta:{name:'专家团队'} },
        { path: '/doctor_details', component: Doctor_details, meta:{name:'专家团队'} },
        { path: '/from', component: resolve => require(['@/components/sub_from.vue'], resolve), meta:{name:'咨询预约'} },
        { path: '/about', component: resolve => require(['@/pages/views/about_us.vue'], resolve), meta:{name:'了解守和堂'} },
        { path: '/administrative', component: resolve => require(['@/pages/views/ke_shi.vue'], resolve), meta:{name:'找科室'} },
        

        { path: '/ttform/form/zhouzheng', component: resolve => require(['@/pages/ttform/ttform_zhouzheng.vue'], resolve) },
        { path: '/ttform/form/zhaoping', component: resolve => require(['@/pages/ttform/zhao_ping_form.vue'], resolve) },
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0}
    }
})

// router.addRoutes([])
// router.beforeEach(function (to, from, next) {

// })

export default router