<template>
    <div>
        <div class="m_nav">
            <div class="m_nav-left">
                <div class="back" v-if="backBtnShow" @click="linkBack()">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="logo">
                    <img src="@/assets/logo.png" alt="">
                </div>
            </div>
            <div class="nav_btn" :class="constOpen ? 'nav_btn_select' : ''" @click="navBtn()">
                <i class="el-icon-s-grid" v-if="constOpen"></i>
                <i class="el-icon-menu" v-else></i>
            </div>
            <div class="nav-const" :class="constOpen ? 'nav-const_open' : ''">
                <div @click="navTo(it.url)" class="nav-const-link" v-for="it in navList" :key="it.id">
                    {{ it.name }}
                </div>
                <div class="nav-const-bg" @click="navBtn()"></div>
            </div>
        </div>

        <router-view></router-view>

        <div class="m_foot">
            <div class="left">
                <p>电话：18482393302</p>
                <p>地址：四川省成都市青羊区-锦里西路84-86号成都青羊守和堂中医互联网医院</p>
                <p>
                    Copyright © 青羊守和堂中医互联网医院 版权所有<br>
                    川公网安备
                        51010502011799号
                </p>
            </div>
            <div class="right">
                <img src="@/assets/logo2.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'m_layout',
    data() {
        return {
            navList: [{
                id: 0,
                name: '首页',
                url: '/index'
            }, {
                id: 1,
                name: '中医资讯',
                url: '/information'
            }, {
                id: 2,
                name: '专家团队',
                url: '/doctor'
            }, {
                id: 3,
                name: '看诊服务',
                url: '/from'
            }, {
                id: 4,
                name: '了解守和堂',
                url: '/about'
            },],
            constOpen: false,
            backBtnShow:false,
        }
    },
    watch:{
        $route:{
            deep:true,
            immediate:true,
            handler(){
                if(this.$route.path == '/index')
                    this.backBtnShow = false
                else
                    this.backBtnShow = true
            }
        }
    },
    methods:{
        linkBack(){
            this.$router.go(-1)
        },
        navBtn() {
            this.constOpen = !this.constOpen
            // 禁止页面滚动：
            if(this.constOpen)
            document.body.style.overflow='hidden'
            else
            // 允许页面滚动：
            document.body.style.overflow='visible'
        },
        navTo(url) {
            this.$router.push({
                path: url,
            })
            this.navBtn()
        }
    }
}
</script>
<style lang="scss" scoped>
.m_nav {
    position: sticky;
    top: 0;
    z-index: 9999999999999;
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    background: url(@/assets/navBg.jpg) no-repeat top center;
    background-size: 100% 100%;
    box-shadow: 0px 5px 14px #969696b4;
    // transition: all 1s ease-out;

    .m_nav-left{
        display: flex;
        align-items: center;
        .back{
            >i{
                color: #555;
                font-size: 2rem;
            }
        }
    }

    .logo {
        width: 30vw;
        max-width: 150px;
        >img {
            width: 100%;
            height: 100%;
        }
    }

    .nav_btn {
        font-size: 1.5rem;
        color: #915052;
        transition: transform 1s;
        -webkit-transition: transform 1s;
    }

    .nav_btn_select {
        font-size: 2rem;
        color: #98181c;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }

    .nav-const {
        height: 0;
        // height: 90vh;
        width: 100%;
        top: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        z-index: 9999999999;
        color: #fff;
        transition: all 1s ease-out;

        .nav-const-link {
            background-color: #98181c;
            padding: 10px;
            width: 100%;
            text-align: center;
            cursor: pointer;

            &:hover {
                color: #adadad;
            }
        }

        .nav-const-bg {
            height: 100%;
            background-color: #49494931;
        }
    }

    .nav-const_open {
        height: 90vh;
    }

}

.m_foot {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    border-top: 10px solid #d8d8d8;
    border-bottom: 10px solid #d8d8d8;

    .left {
        width: 60%;
        font-size: 12px;

        p {
            word-break: break-all;
        }
    }

    .right {
        margin-left: 10px;
        flex: 1;
        max-width: 150px;
        >img {
            width: 100%;
        }
    }
}
</style>