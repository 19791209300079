var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zixun-right"},[_c('H_title',{attrs:{"left-txt":"快速通道","type":"bg","right-txt":"no"}}),_c('div',{staticClass:"zixun-right-const"},[_c('div',{staticClass:"zixun-right-list",on:{"click":function($event){return _vm.navto()}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"zixun-right-list",on:{"click":function($event){return _vm.navto()}}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"zixun-right-list",on:{"click":function($event){return _vm.toKuaiShang()}}},[_vm._m(4),_vm._m(5)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zixun-right-list-img"},[_c('i',{staticClass:"el-icon-document-copy"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zixun-right-list-const"},[_c('div',{staticClass:"zixun-right-list-const-head"},[_vm._v(" 预约挂号 ")]),_c('div',{staticClass:"zixun-right-list-const-txt"},[_vm._v(" 方便、快捷，节约时间 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zixun-right-list-img"},[_c('i',{staticClass:"el-icon-s-order"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zixun-right-list-const"},[_c('div',{staticClass:"zixun-right-list-const-head"},[_vm._v(" 就诊指南 ")]),_c('div',{staticClass:"zixun-right-list-const-txt"},[_vm._v(" 让你快速了解就诊流程 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zixun-right-list-img"},[_c('i',{staticClass:"el-icon-user"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zixun-right-list-const"},[_c('div',{staticClass:"zixun-right-list-const-head"},[_vm._v(" 在线咨询 ")]),_c('div',{staticClass:"zixun-right-list-const-txt"},[_vm._v(" 不了解的，请在线咨询 ")])])
}]

export { render, staticRenderFns }