<template>
    <div class="notFound">
        <div class="notFound-img">
          <img src="../../assets/notPage.png" alt="页面不存在">
        </div>
        <p class="notFound-txt">页面不存在</p>
        <el-button type="text" @click="goBack">返回 </el-button>
    </div>
</template>

<script>
export default {
  name: 'notFound',
  methods:{
    goBack(){
        this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
  .notFound{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .notFound-img{
    width: 40vw;
    height: 40vw;
  }
  .notFound-img>img{
    width: 100%;
    height: 100%;
  }
  .notFound-txt{
    font-size: 24px;
    font-weight: bold;
  }
</style>