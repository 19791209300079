<template>
    <div class="foot">
        <div class="foot-cont">
            <div class="logo2">
                <img src="@/assets/logo2.png" alt="">
            </div>
            <div class="foot-form">
                <div class="foot-form-cont">
                    <h3>我们联系您</h3>
                    <div class="to">
                        <textarea v-model="formData.zzms" name="remarks" id="" cols="30" rows="10" placeholder="问题备注"></textarea>
                        <div>
                            <input v-model="formData.name" name="name" type="text" placeholder="姓名">
                            <input v-model.number="formData.tel" name="phone" type="number" placeholder="联系电话">
                            <div class="btn" @click="yysubmit">提交</div>
                        </div>
                    </div>
                    <h3 style="margin-top: 30px;">联系我们</h3>
                    <div class="come">
                        <p>客服热线：18482393302</p>
                        <p>诊所地址：四川省成都市青羊区-锦里西路84-86号成都青羊守和堂中医互联网医院</p>
                    </div>
                </div>
                <div class="foot-qrcode">
                </div>
            </div>
        </div>
        <div class="foot-bottom">
            <p>成都青羊守和堂中医互联网医院 版权所有
                蜀ICP备2023021725号-1
            </p>
            <!-- 互联网药品信息服务资格证书编号:(京)-非经营性-0000-0000</p> -->
            <div style="width: 100%;display: flex;justify-content: center;align-items: center;">
                <img src="@/assets/3d617b15031212fab9ed0288dca6cfe.png" style="float:left;" />
                <a href="https://beian.miit.gov.cn/" target="_blank">川公网安备
                    51010502011799号</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Pc-foot',
    data() {
        return {
            formData: {
                name: undefined,
                age: undefined,
                city: undefined,
                tel: undefined,
                sex: 0,
                zzms: '',
                usermake: '',
                projectid: undefined,
            },
            yysubmitOk: true,
        };
    },
    methods: {
        yysubmit() {
            if (!this.yysubmitOk) {
                this.$message({
                    message: '您已经提交过了',
                    type: 'error',
                    offset: 80
                });
                return
            }
            if (!this.formData.name) {
                this.$message({
                    message: '请填入您的姓名',
                    type: 'error',
                    offset: 80
                });
                return
            }
            if (!this.formData.tel) {
                this.$message({
                    message: '请填入您的联系方式',
                    type: 'error',
                    offset: 80
                });
                return
            }
            this.yysubmitOk = false
            this.formData.yyzj = ''
            // this.formData.suburl = '抖音守和健康'
            // this.formData.suburl = '快手守和健康'
            this.formData.suburl = this.$api.suburl
            this.formData.tjtime = new Date().toISOString()
            this.formData.sourcerul = this.$api.sourcerul
            this.formData.isgt = 1

            // console.log(this.formData)

            this.$api.subLiuyan(this.formData).then(res => {
                if (res.statusCode == 200) {
                    this.$message({
                        message: '提交成功，专家会及时回复',
                        type: 'success',
                        offset: 80
                    });
                    setTimeout(() => {
                        this.yysubmitOk = true
                    }, 8000)
                } else {
                    this.yysubmitOk = true
                    this.$message.error(res.errors)
                    this.$message({
                        message: res.errors,
                        type: 'error',
                        offset: 80
                    });
                }
            }).catch(err => {
                console.log(err);
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.foot {
    width: 100%;
    height: 500px;
    margin-top: 40px;
    position: relative;
    overflow: hidden;

    input,
    textarea {
        border: 2px solid #98181c;
        padding: 5px;
        border-radius: 5px;
        outline: none;
        width: 100%;
    }

    .foot-cont {
        width: 1270px;
        height: calc(100% - 70px);
        margin: 0 auto;
        display: flex;

        .logo2 {
            padding: 40px 0;
            padding-right: 30px;
            border-right: 1px solid #99a9bf;
        }

        .foot-form {
            width: 100%;
            padding: 40px;
            padding-right: 0;
            display: flex;
            justify-content: space-between;

            .foot-form-cont {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .to {
                    display: flex;

                    >div {
                        margin-left: 20px;
                        width: calc(100% - 20px);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        input {
                            height: 40px;
                        }
                    }

                    .btn {
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 10px;
                        background-color: #b3282c;
                        text-align: center;
                        color: #fff;
                        cursor: pointer;

                        &:hover {
                            background-color: #b64246;
                        }
                    }
                }

                .come {
                    line-height: 30px;
                }
            }
        }
    }

    .foot-bottom {
        width: 100%;
        height: 70px;
        background-color: #98181c;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px 0;
        font-size: 12px;
    }

}
</style>