<template>
    <div class="title">
        <div class="title-txt"><b></b>{{ title }}</div>
        <div class="title-btn" @click="navTo()">
            {{linkTxt}}
        </div>
    </div>
</template>
<script>
export default {
    name:'m_title',
    props:{
        title:{
            default:'',
            type:String
        },
        btnTxt:{
            default:'更多',
            type:String
        },
        linkTxt:{
            default:'更多>'
        },
        url:{
            default:'/index',
            type:String
        }
    },
    data() {
        return {
            property: 'value',
        };
    },
    methods:{
        navTo() {
            this.$router.push({
                path: this.url,
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .title-txt {
        display: flex;
        align-items: center;

        >b {
            width: 5px;
            height: 16px;
            border-radius: 10px;
            margin-right: 5px;
            background-color: rgb(126, 32, 32);
        }
    }

    .title-btn {
        color: #636363;
    }
}
</style>