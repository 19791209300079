<template>
    <div class="zixun-right">
        <H_title left-txt="快速通道" type="bg" right-txt="no"></H_title>
        <div class="zixun-right-const">
            <div class="zixun-right-list" @click="navto()">
                <div class="zixun-right-list-img">
                    <i class="el-icon-document-copy"></i>
                </div>
                <div class="zixun-right-list-const">
                    <div class="zixun-right-list-const-head">
                        预约挂号
                    </div>
                    <div class="zixun-right-list-const-txt">
                        方便、快捷，节约时间
                    </div>
                </div>
            </div>
            <div class="zixun-right-list" @click="navto()">
                <div class="zixun-right-list-img">
                    <i class="el-icon-s-order"></i>
                </div>
                <div class="zixun-right-list-const">
                    <div class="zixun-right-list-const-head">
                        就诊指南
                    </div>
                    <div class="zixun-right-list-const-txt">
                        让你快速了解就诊流程
                    </div>
                </div>
            </div>
            <div class="zixun-right-list" @click="toKuaiShang()">
                <div class="zixun-right-list-img">
                    <i class="el-icon-user"></i>
                </div>
                <div class="zixun-right-list-const">
                    <div class="zixun-right-list-const-head">
                        在线咨询
                    </div>
                    <div class="zixun-right-list-const-txt">
                        不了解的，请在线咨询
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Yu_yue',
    methods:{
        navto(){
            this.$router.push({
                path: '/from',
                
            })
        },
        toKuaiShang(){
            window.open('https://rgdk16.kuaishang.cn/bs/im.htm?cas=119563___405556&fi=122863&ism=1','_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .zixun-right{
            flex: 1;
            height: 350px;
            border: 1px solid #ddd;
            .zixun-right-const{
                height: calc(100% - 41px);
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 0 20px;
                .zixun-right-list{
                    display: flex;
                    cursor: pointer;
                    .zixun-right-list-img{
                        width: 60px;
                        height: 60px;
                        border: 1px solid #98181c;
                        border-radius: 50%;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        >i{
                            font-size: 40px;
                            color: #98181c;
                        }
                    }
                    .zixun-right-list-const{
                        display: flex;
                        flex-direction: column;
                        height: 60px;
                        justify-content: space-between;
                        padding: 5px 0;
                        border-bottom: 1px solid #99a9bf;
                        &:hover{
                            color: #98181c;
                        }
                        .zixun-right-list-const-head{
                            font-size: 18px;
                        }
                    }
                }
            }
        }
</style>