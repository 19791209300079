<template>
    <div class="doc">
        <div class="doc-img">
            <img :src="doctor.photourl" alt="">
        </div>
        <div class="doc-name">
            {{ doctor.name }}
        </div>
        <div class="doc-jobtitle">
            {{ doctor.jobtitle }}
        </div>
        <div class="yuyue-btn" @click="toForm(doctor.name)">
            在线预约
        </div>
        <div class="doc-box">
            <div class="doc-box-title">
                个人简介
            </div>
            <div class="doc-box-cont">
                {{ doctor.docBrief }}
            </div>
        </div>
        <div class="doc-box">
            <div class="doc-box-title">
                擅长病种
            </div>
            <div class="doc-box-cont">
                {{ doctor.docskilled }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {
            list: {
                currentPage: 1,
                pageSize: 99999
            },
            doctor: {},
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        // console.log(this.$route.query.id)
        this.getdoctorlist(this.$route.query?.id)
    },
    methods: {
        toForm(doc){
            this.$router.push({
                path: `/from?doc=${doc}`,
            })
        },
        getdoctorlist(id) {
            // 医生列表
            // this.list.id = ''
            // if (id) this.list = { ...this.list, id }
            this.$api.getdoctorlist({
                name: id
            }).then(res => {
                // console.log(res);
                if (res.statusCode == 200) {
                    this.doctor = res.data[0]
                }
            }).catch(err => {
                console.log(err);
            })
        },
    }
};
</script>
<style lang='scss' scoped>
.doc {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;


    .doc-img {
        margin: 20px 0;
        width: 20vw;
        height: 20vw;
        min-width: 100px;
        min-height: 100px;
        background-color: antiquewhite;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .doc-name {
        font-weight: bold;
        font-size: 36px;
        font-weight: bold;
    }

    .doc-jobtitle {
        margin: 20px 0;
    }

    .yuyue-btn {
        color: #fff;
        background-color: rgb(255, 0, 0);
        margin-bottom: 50px;
        padding: 5px 25px;
        background: url(@/assets/btn01.png) no-repeat center center;
        background-size: 100% 100%;
        cursor: pointer;
    }

    .doc-box {
        margin-bottom: 30px;
        width: 100%;
        .doc-box-title {
            padding: 10px 20px;
            background-color: rgb(204, 168, 125);
            display: inline-block;
            font-weight: bold;
        }

        .doc-box-cont {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 28px;
            // text-indent: 2em;
            white-space:pre-wrap;
        }
    }

}</style>