<template>
    <div class="doctor-page">
        <M_title title="专家团队" linkTxt=""></M_title>
        <div class="doctor" v-if="!nomoreShow">
            <div v-for="it in doctorList" :key="it.id" class="doctor-list">
                <div class="doctor-img">
                    <div class="img">
                        <img :src="it.photourl" alt="">
                    </div>
                    <div class="name">{{ it.name }}</div>
                </div>
                <div class="doctor-txt">
                    <div class="docskilled txt2flow">
                        <b>擅长</b>:{{ it.docskilled }}
                    </div>
                    <div class="docskilled txt2flow">
                        <b>简介</b>:{{ it.docBrief }}
                    </div>
                    <div class="btns">
                        <div class="btn" @click="toDoc(it.name)">查看详情</div>
                        <div class="btn btn2" @click="toForm(it.name)">预约挂号</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nomore" v-else>
            <h3 style="text-align: center;">没有更多数据...</h3>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            property: 'value',
            list: {
                currentPage: 1,
                pageSize: 99999
            },
            doctorList: [],
            nomoreShow:false,
        };
    },
    created() {
        console.log(this.$route.query.doc)
        if(this.$route.query.doc)
            this.getdoctorlist(this.$route.query.doc)
        else 
            this.getdoctorlist()
    },
    watch:{
        '$route.query.doc':{
            handler(val){
                this.getdoctorlist(val)
            }
        }
    },
    methods: {
        toDoc(id){
            this.$router.push({
                path: '/doctor_details',
                query:{
                    id
                }
            })
        },
        getdoctorlist(doc) {
            // 医生列表
            this.list.name = ''
            if(doc) this.list = {...this.list ,name: doc}
            this.$api.getdoctorlist(this.list).then(res => {
                console.log(res);
                if (res.statusCode == 200){
                    this.doctorList = res.data
                    this.nomoreShow = res.data.length===0
                }
            }).catch(err => {
                console.log(err);
            })
        },
        toForm(doc){
            this.$router.push({
                path: `/from?doc=${doc}`,
            })
        }
    }
} 
</script>

<style lang="scss" scoped>
.doctor {
    padding: 10px;
    min-height: 70vh;
}

.doctor-list {
    display: flex;
    padding: 10px;
    border: 1px solid #99a9bf;
    margin-bottom: 10px;

    .doctor-img {
        width: 4rem;
        min-height: 6rem;
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        // justify-content: space-between;
        .img {
            width: 5rem;
            height: 5rem;
            border: .2rem solid #e68a00;
            border-radius: 50%;
            overflow: hidden;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .name {
            word-break: break-all;
            text-align: center;
            font-size: 1.1rem;
            margin-top: 10px;
            font-weight: bold;
        }
    }

    .doctor-txt {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        .docskilled {
            margin-bottom: 5px;
            font-size: 14px;
        }

        .btns {
            display: flex;
            justify-content: flex-end;

            .btn {
                background: url(@/assets/btn01.png) no-repeat center center;
                background-size: 100% 100%;
                padding: 3px 12px;
                color: #fff;
                font-size: 14px;
                cursor: pointer;
            }

            .btn2 {
                margin-left: 20px;
                background: url(@/assets/btn02.png) no-repeat center center;
                background-size: 100% 100%;
            }
        }
    }
}
</style>