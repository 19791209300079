<template>
  <div v-if='tt' id="app">
    <router-view></router-view>
  </div>
  <div v-else-if="pc" id="app">
    <Pc_top></Pc_top>
    <Pc_nav></Pc_nav>
    <router-view v-if="is_index"></router-view>
    <Views_layout v-else></Views_layout>
    <Pc_foot></Pc_foot>
  </div>
  <div v-else id="app">
    <M_layout></M_layout>
  </div>
</template>

<script>
import Pc_top from '@/components/top'
import Pc_nav from '@/components/pc_nav'
import Pc_foot from '@/components/pc_foot'
import Views_layout from '@/pages/sys/views_layout'
import M_layout from '@/pages/sys/m_layout'

export default {
  // name: 'App',
  components: {
    Pc_top,
    Pc_nav,
    Pc_foot,
    Views_layout,
    M_layout
  },
  data() {
    return {
      pc: true,
      is_index: true,
      tt:false,
    };
  },
  watch: {
    $route() {
      // $route(to, from) {
      // console.log("到哪个页面去", to.path);
      // console.log("从哪个页面来", from.path);
      // console.log(this.$route);
      // console.log(this.$route.path)
      this.tt = this.$route.path.slice(0,7)=='/ttform'
      this.init()
    }
  },
  created() {
    this.init()

    // 区分页面加script标签
    let headDom = document.head || document.getElementsByTagName('head')[0];
    let script = document.createElement('script')
    script.type = 'text/javascript';
    script.charset = 'utf-8';
     script.src = this._isMobile()
     ? 
     'https://rgdk16.kuaishang.cn/bs/ks.j?cI=405556&fI=122863&ism=1'
     : 
     'https://rgdk16.kuaishang.cn/bs/ks.j?cI=405556&fI=122863';
  
    headDom.appendChild(script)

  },
  methods: {
    
    init() {
      this.is_index = this.$route.path == '/index'
      if (this.$route.meta?.type == '404')
        this.pc = false
      else
        this.pc = !this._isMobile()
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return window.innerWidth <= 1270 || flag;
    }
  }
}
</script>

<style lang="scss">
.input .el-input__inner {
  border: none; 
  // background-color: rgba(255, 255, 255, 0);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: url(./assets/background.jpg) no-repeat top center;
  background-size: 100% 100%;
  min-width: 100vw;
  min-height: 100vh;
}

/* 两行文字省略号 */
.txt2flow {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* 一行文字省略号 */
.txt1flow {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* 滚动条整体 */
body::-webkit-scrollbar {
  height: 20px;
  width: 10px;
}

/* 两个滚动条交接处 -- x轴和y轴 */
body::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* 滚动条滑块 */
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #98181c;
}

/* 滚动条轨道 */
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}</style>
