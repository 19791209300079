<template>
    <div>
        <el-carousel height="50vw">
            <el-carousel-item v-for="item in bannerList" :key="item.id">
                <el-image @click="urlTo(item.jumpUrl)" style="width: 100%; height: 100%" :src="item.imgUrl" fit="cover"></el-image>
            </el-carousel-item>
        </el-carousel>
        <div class="btn-list">
            <div class="btns" @click="navTo('/about')">
                <div class="icon">
                    <i class="el-icon-house"></i>
                </div>
                <span>关于医院</span>
            </div>
            <div class="btns" @click="toKuaiShang()">
                <div class="icon">
                    <i class="el-icon-edit-outline"></i>
                </div>
                <span>咨询预约</span>
            </div>
            <div class="btns" @click="navTo('/information')">
                <div class="icon">
                    <i class="el-icon-tickets"></i>
                </div>
                <span>中医资讯</span>
            </div>
            <div class="btns" @click="navTo('/doctor')">
                <div class="icon">
                    <i class="el-icon-user"></i>
                </div>
                <span>专家团队</span>
            </div>
        </div>
        <div class="const-box">
            <M_title title="专家团队" url="/doctor"></M_title>
            <div class="doctor">
                <div v-for="it in doctorList" :key="it.id" class="doctor-list">
                    <div class="doctor-img">
                        <div class="img">
                            <img :src="it.photourl" alt="">
                        </div>
                        <div class="name">{{ it.name }}</div>
                    </div>
                    <div class="doctor-txt">
                        <div class="docskilled txt2flow">
                            擅长:{{ it.docskilled }}
                        </div>
                        <div class="btns">
                            <div class="btn" @click="toDoc(it.name)">查看详情</div>
                            <div class="btn btn2" @click="toForm(it.name)">预约挂号</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div>
            <M_title title="特色科室" linkTxt=""></M_title>
            <div class="department">
                <div v-for="it in Keshilist" :key="it.id" class="department-list">
                    <div class="img"><img :src="it.imgurl" alt=""></div>
                    <div class="name">{{ it.title }}</div>
                </div>
                <div @click="getdepartment()" class="department-list">
                    <div class="img" v-if="Keshilist.length ==3"><i class="el-icon-caret-bottom"></i></div>
                    <div class="img" v-else><i class="el-icon-caret-top"></i></div>
                    <div class="name" style="color: #e68a00;font-size: 14px;">{{ Keshilist.length == 3 ? '打开更多' : '收起' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="const-box">
            <Zixun_information></Zixun_information>
        </div>
        <div>
            <sub_from></sub_from>
        </div>
    </div>
</template>
<script>
import Zixun_information from '@/components/zixun_information'
import sub_from from '@/components/sub_from.vue'

export default {
    components: {
        Zixun_information,
        sub_from
    },
    data() {
        return {
            sex: 1,
            doctorList: [

            ],
            Keshilist: [],
            departmentLength: 9,
            bannerList: [],
        };
    },
    created() {
        this.init()
    },
    methods: {
        toDoc(id) {
            this.$router.push({
                path: '/doctor_details',
                query: {
                    id
                }
            })
        },
        toKuaiShang(){
            window.open('https://rgdk16.kuaishang.cn/bs/im.htm?cas=119563___405556&fi=122863&ism=1','_blank')
        },
        toForm(doc) {
            this.$router.push({
                path: `/from?doc=${doc}`,
            })
        },
        navTo(url) {
            this.$router.push({
                path: url,
            })
        },
        getdepartment() {
            if (this.departmentLength == 3) this.departmentLength = 999
            else this.departmentLength = 3
            this.$api.getKeshilist({
                page: 1,
                limit: this.departmentLength
            }).then(res => {
                // console.log(res);
                if (res.statusCode == 200)
                    this.Keshilist = res.data.list
            }).catch(err => {
                console.log(err);
            })
        },
        init() {
            // 医生列表
            this.$api.getdoctorlist({
                pageSize: 3
            }).then(res => {
                // console.log(res);
                if (res.statusCode == 200)
                    this.doctorList = res.data
            }).catch(err => {
                console.log(err);
            })
            // 科室列表
            this.getdepartment()
            // 幻灯片
            this.$api.getslidelist().then(res => {
                // console.log(res);
                if (res.statusCode == 200)
                    this.bannerList = res.data
            }).catch(err => {
                console.log(err);
            })
        },
        urlTo(url){
            window.open(url,'_blank')
        },
    }
}
</script>
<style lang="scss" scoped>
.btn-list {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-top: 10px solid #d8d8d8;
    border-bottom: 10px solid #d8d8d8;

    .btns {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            width: 3.5rem;
            height: 3.5rem;
            background-color: rgb(133, 34, 34);
            font-size: 2rem;
            color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
        }

        >span {
            font-size: 14px;
        }
    }


}



.const-box {
    width: 100%;
    height: 100%;
    // border-top: 10px solid #d8d8d8;
    border-bottom: 10px solid #d8d8d8;
}

.department {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.department-list {
    width: 25%;
    padding: 7px 0;
    border: 1px solid #e68a00;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    .img {
        width: 3rem;
        height: 3rem;
        border: 1px solid #e68a00;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        >i {
            color: #e68a00;
            font-weight: bold;
            font-size: 1.5rem;
        }

        >img {
            width: 100%;
            height: 100%;
        }
    }
}

.doctor {
    padding: 10px;
}

.doctor-list {
    display: flex;
    padding: 10px;
    border: 1px solid #99a9bf;
    margin-bottom: 10px;

    .doctor-img {
        width: 4rem;
        min-height: 6rem;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .img {
            width: 4rem;
            height: 4rem;
            border: .2rem solid #e68a00;
            border-radius: 50%;
            overflow: hidden;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .name {
            text-align: center;
            font-size: 1.1rem;
            word-break: break-all;
        }
    }

    .doctor-txt {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        .docskilled {
            word-break: break-all;
        }

        .btns {
            display: flex;
            justify-content: flex-end;

            .btn {
                background: url(@/assets/btn01.png) no-repeat center center;
                background-size: 100% 100%;
                padding: 3px 12px;
                color: #fff;
                font-size: 12px;
                cursor: pointer;
            }

            .btn2 {
                margin-left: 20px;
                background: url(@/assets/btn02.png) no-repeat center center;
                background-size: 100% 100%;
            }
        }
    }
}

// swiper
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>