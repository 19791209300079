<template>
    <div>
        <Zixun_information :listLength="9" rightTxt="no" :paginationShow="true"></Zixun_information>
        
    </div>
</template>
<script>
import Zixun_information from '@/components/zixun_information'
export default {
    components:{
        Zixun_information
    }
}
</script>
<style>
.pagination{
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>