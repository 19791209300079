import api from './http'

let auditId = '443920428163926469'//守和堂官网

export const suburl = '守和堂官网'
export const sourcerul = 'web'

// 获取医生
export const getdoctorlist = (data ) => api.post('/api/mini/getdoctorlist', {...data,classid:auditId})

//提交留言
export const subLiuyan = (data ) => api.post('/api/mini/leavemessage', {classid:auditId,...data})

// 审核开关
// export const audit = () => api.post('/api/mini/audit', {id:auditId})

//获取分类列表
// export const getarticlecategory = () => api.get('/api/mini/getarticlecategory')

let storeId = '444281352448117189'// 资讯文章id
//获取文章列表
export const getarticlelist = (data ) => api.post('/api/mini/getarticlelist', {id: storeId,...data})

let keshiId = '444281218402355653'// 科室文章id
//获取科室列表
export const getKeshilist = (data ) => api.post('/api/mini/getarticlelist', {id: keshiId,...data})

let aboutId = '444643915962780101'// 关于文章id
//获取关于文章
export const getabout = () => api.post('/api/mini/getarticlelist', {id: aboutId, page: 1,limit: 1})

//获取详情
export const getDetail = (data) => api.post('/api/mini/getarticledetail', data)

//获取幻灯片
export const getslidelist = () => api.get('/api/mini/getslidelist')




