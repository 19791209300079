<template>
    <div class="index">
        <el-carousel trigger="click" height="600px">
            <el-carousel-item v-for="item in bannerList" :key="item.id">
                <!-- <h1>{{ item }}</h1> -->
                <el-image @click="urlTo(item.jumpUrl)" style="width: 100%; height: 100%" :src="item.imgUrl" fit="cover"></el-image>
            </el-carousel-item>
        </el-carousel>
        <div class="const">
            <H_title url="/about" left-txt="【 关于守和堂 】"></H_title>
            <div class="about">
                <div class="about-img">
                    <img :src="about.imgurl" alt="青羊区守和堂" style="width: 100%;  height: 100%">
                    <!-- <el-image alt="青羊区守和堂" lazy style="width: 100%;  height: 100%" src="##" fit="cover">
                        <div slot="error" style="display: flex;align-items: center;justify-content: center;width: 100%;  height: 100%;">
                            798
                        </div>
                    </el-image> -->
                </div>
                <div class="about-txt">
                    {{ about.subtitle }}
                </div>

            </div>
            <br>
            <H_title url="/administrative" left-txt="【 特色科室 】"></H_title>
            <!-- <div class="characteristicBox"> -->
            <div class="characteristic" ref="characteristic">
                <div v-for="(it, index) in Keshilist" :key="it.id" @mouseover="hovercharacteristicIndex = index"
                    @mouseout="hovercharacteristicIndex = -1" :class="hovercharacteristicIndex == index ? 'border-' : ''"
                    class="characteristic-list">
                    <div class="characteristic-list-img">
                        <el-image style="width: 100%; height: 100%" :src="it.imgurl" fit="cover"></el-image>
                    </div>
                    <div class="characteristic-list-head">{{ it.title }}</div>
                    <!--<div class="characteristic-list-txt">
                        {{ it.subtitle }}
                    </div>-->
                </div>
            </div>
            <!-- </div> -->
            <br>
            <H_title left-txt="【 推荐专家 】" type="bg" url="/doctor"></H_title>
            <div class="doctor" style="width: 100%;height: 500px;border: 1px solid #ddd;">
                <div>
                    <p class="doctor-lists-title">随时随地，在线预约</p>
                    <div class="doctor-list">
                        <div :class="i == lookDoctor ? 'doctor-select' : ''" class="doctor-lists"
                            v-for="(it, i) in doctorList" :key="it.id" @click="changedoctor(i)">
                            <div class="doctor-lists-img">
                                <el-image style="width: 100%; height: 100%" :src="it.photourl" fit="cover"></el-image>
                            </div>
                            <div class="doctor-lists-txt">
                                <div class="doctor-lists-name">{{ it.name }}</div>
                                <div class="doctor-lists-jobtitle">{{ it.jobtitle }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="doctor-const">
                    <div class="doctor-txt">
                        <div class="doctor-txt-1">
                            <b class="name">{{ doctorList[lookDoctor]?.name }}</b>
                            <span v-if="doctorList[lookDoctor]?.jobtitle">
                                {{ doctorList[lookDoctor]?.jobtitle }}
                            </span>
                        </div>
                        <div class="doctor-txt-2">
                            {{ doctorList[lookDoctor]?.docskilled }}
                        </div>
                        <div class="doctor-txt-3">
                            {{ doctorList[lookDoctor]?.docBrief }}
                        </div>
                        <div class="doctor-txt-4">
                            <div>
                                <p>{{ doctorList[lookDoctor]?.num }}+</p>
                                <p class="under-txt">看诊经验</p>
                            </div>
                            <i></i>
                            <div>
                                <p>中医门诊</p>
                                <p class="under-txt">所在科室</p>
                            </div>
                        </div>
                        <div class="doctor-txt-5">
                            <div class="doctor-txt-btn1" @click="toForm()">立刻预约</div>
                            <div class="doctor-txt-btn2" @click="toKuaiShang()">在线咨询</div>
                        </div>
                    </div>
                    <div class="doctor-img">
                        <el-image lazy style="width: 100%; height: 100%" :src="doctorList[lookDoctor]?.photourl"
                            fit="cover"></el-image>
                    </div>
                </div>
            </div>
            <br>
            <div class="zixun">
                <div style="width: 75%;margin-right: 20px;">
                    <Zixun_information></Zixun_information>
                </div>
                <Yu_yue></Yu_yue>
            </div>
        </div>
    </div>
</template>
<script>
import Zixun_information from '@/components/zixun_information'
import Yu_yue from '@/components/yuyue'
export default {
    components: {
        Zixun_information,
        Yu_yue
    },
    data() {
        return {
            doctorList: [],
            bannerList: [],
            Keshilist: [],
            hovercharacteristicIndex: -1,
            lookDoctor: 0,
            about: {},
        };
    },
    created() {
        this.init()
    },
    updated() {
        const container = this.$refs.characteristic; // 获取容器元素
        let isDown = false, // 是否按下鼠标
            startX, // 鼠标起始位置
            scrollLeft; // 滚动条位置
        container.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - container.offsetLeft;
            scrollLeft = container.scrollLeft;
        });

        container.addEventListener('mouseup', () => {
            isDown = false;
        });

        container.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX) * 2;
            container.scrollLeft = scrollLeft - walk;
        });
    },
    methods: {
        toForm() {
            this.$router.push({
                path: '/from',
            })
        },
        toKuaiShang(){
            window.open('https://rgdk16.kuaishang.cn/bs/im.htm?cas=119563___405556&fi=122863&ism=1','_blank')
        },
        changedoctor(i) {
            this.lookDoctor = i
        },
        rand(min, max) {
            return Math.floor(Math.random() * (max - min)) + min
        },
        urlTo(url){
            console.log(url)
            window.open(url,'_blank')
        },
        init() {
            // 医生列表
            this.$api.getdoctorlist({
                pageSize: 5
            }).then(res => {
                // console.log(res);
                if (res.statusCode == 200)
                    this.doctorList = res.data.map(it => {
                        return {
                            ...it,
                            num: Math.floor(Math.random() * (5000 - 3200)) + 3200
                        }
                    })
            }).catch(err => {
                console.log(err);
            })
            // 科室列表
            this.$api.getKeshilist({
                page: 1,
                limit: 99999999
            }).then(res => {
                // console.log(res);
                if (res.statusCode == 200)
                    this.Keshilist = res.data.list
            }).catch(err => {
                console.log(err);
            })
            // 幻灯片
            this.$api.getslidelist().then(res => {
                console.log(res);
                if (res.statusCode == 200)
                    this.bannerList = res.data
            }).catch(err => {
                console.log(err);
            })

            this.$api.getabout().then(res => {
                if (res.statusCode == 200)
                    this.about = res.data.list[0]
                // console.log(this.about);
            }).catch(err => {
                console.log(err);
            })
        },
    },
}
</script>
<style lang="scss" scoped>
$color: #98181c;

/* 滚动条整体 */
.about-txt::-webkit-scrollbar {
    height: 20px;
    width: 12px;
}

/* 两个滚动条交接处 -- x轴和y轴 */
.about-txt::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* 滚动条滑块 */
.about-txt::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: $color;
}

/* 滚动条轨道 */
.about-txt::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}

.const {
    width: 1270px;
    // height: 1000px;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 2px 3px 13px #b1b1b1ad;
    padding: 20px;

    .about {
        position: relative;
        margin-bottom: 50px;

        .about-img {
            width: 75%;
            height: 600px;
            border: 1px solid;
        }

        .about-txt {
            position: absolute;
            bottom: -50px;
            right: 0;
            height: 260px;
            width: 57%;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(180deg, #b12020 0%, #98181c 100%);
            overflow: auto;
            word-break: break-all;
            padding: 20px;
            transition-property: all;
            transition-duration: 0.8s;
            white-space: pre-wrap;
            font-weight: bold;
            line-height: 30px;
            // transition-timing-function: cubic-bezier(0.49, 1.59, 0.46, -0.21);
        }
    }

    // @keyframes slide {
    //     0% {
    //         transform: translateX(0);
    //     }

    //     100% {
    //         transform: translateX(-50%);
    //     }
    // }

    overflow: hidden;

    // .characteristicBox {
    //     display: flex;
    //     flex-wrap: nowrap;
    //     // overflow: hidden;
    //     // animation: slide 10s linear infinite;

    //     // &:hover {
    //     //     animation: alternate;
    //     // }
    // }

    .characteristic {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;

        // overflow: hidden;
        .border- {
            border-bottom: 3px solid #98181c !important;
        }

        &::-webkit-scrollbar {
            width: 0 !important;
        }

        &::-webkit-scrollbar {
            width: 0 !important;
            height: 0;
        }

        .characteristic-list {
            border: 1px solid #d1d1d1;
            border-bottom: 3px solid #a2a7ad;
            cursor: pointer;
            margin: 0 5px;
            width: 200px;
            height: 230px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            word-break: break-all;
            flex-shrink: 0;

            .characteristic-list-img {
                border: 2px solid $color;
                width: 150px;
                height: 150px;
            }

            .characteristic-list-head {
                font-size: 20px;
            }

            .characteristic-list-txt {
                width: 100%;
                // height: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
        }
    }

    .doctor {
        display: flex;

        .doctor-lists-title {
            width: 300px;
            height: 100px;
            line-height: 100px;
            text-align: center;
        }

        .doctor-list {
            width: 305px;
            height: calc(100% - 120px);
            margin-bottom: 20px;
            overflow: auto;

            .doctor-lists {
                display: flex;
                padding: 0 20px;
                margin: 10px 0;
                border-left: 2px solid #fff;
                cursor: pointer;

                .doctor-lists-img {
                    width: 80px;
                    height: 100px;
                    background-color: aqua;
                    margin-right: 10px;
                }

                .doctor-lists-txt {
                    display: flex;
                    flex-direction: column;
                    padding: 15px 0;
                    justify-content: space-between;
                }

                .doctor-lists-name {
                    font-weight: bold;
                }
            }

            .doctor-select {
                background-color: #e3ecfabe;
                border-left: 2px solid #98181c;
            }
        }

        background:url(@/assets/xspt_icon6_bg.png) no-repeat center center;
        background-size: 100% 100%;

        .doctor-const {
            flex: 1;
            display: flex;

            .doctor-txt {
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .doctor-txt-1 {
                    height: 50px;
                    display: flex;
                    align-items: center;

                    >.name {
                        font-size: 30px;
                        margin-right: 10px;
                    }

                    >span {
                        background-color: #b12020;
                        padding: 2px 10px;
                        font-size: 14px;
                        color: #fff;
                        border-radius: 10px 0 10px 0;
                    }
                }

                .doctor-txt-2 {
                    color: #b12020;
                    border: 1px solid #b12020;
                    padding: 3px 5px;
                    border-radius: 10px 0 10px 0;
                    white-space: pre-wrap;
                }

                .doctor-txt-3 {
                    width: 100%;
                    max-height: 150px;
                    overflow: auto;
                    white-space: pre-wrap;
                }

                .doctor-txt-4 {
                    display: flex;
                    align-items: center;

                    >i {
                        height: 80px;
                        margin: 0 50px;
                        width: 2px;
                        background-color: #777;
                    }

                    >div {
                        height: 80px;

                        >p {
                            font-size: 28px;
                            font-weight: bold;
                            color: #b12020;

                            >span {
                                font-size: 12px;
                            }
                        }

                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .under-txt {
                            font-size: 14px;
                            color: #6b6b6b;
                            font-weight: 400;
                        }
                    }
                }

                .doctor-txt-5 {
                    display: flex;
                    justify-content: space-around;
                    color: #fff;

                    .doctor-txt-btn1 {
                        padding: 5px 25px;
                        background: url(@/assets/btn01.png) no-repeat center center;
                        background-size: 100% 100%;
                        margin-right: 50px;
                        cursor: pointer;
                    }

                    .doctor-txt-btn2 {
                        padding: 5px 25px;
                        background: url(@/assets/btn02.png) no-repeat center center;
                        background-size: 100% 100%;
                        cursor: pointer;
                    }
                }

            }

            .doctor-img {
                width: 350px;
                height: 100%;
                background-color: aqua;
            }
        }
    }

    .zixun {
        width: 100%;
        display: flex;
        height: 350px;


    }
}

/* 滚动条整体 */
.doctor-list::-webkit-scrollbar {
    height: 20px;
    width: 5px;
}

/* 两个滚动条交接处 -- x轴和y轴 */
.doctor-list::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* 滚动条滑块 */
.doctor-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: $color;
}

/* 滚动条轨道 */
.doctor-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}

/* 滚动条整体 */
.doctor-txt-3::-webkit-scrollbar {
    height: 20px;
    width: 5px;
}

/* 两个滚动条交接处 -- x轴和y轴 */
.doctor-txt-3::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* 滚动条滑块 */
.doctor-txt-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: $color;
}

/* 滚动条轨道 */
.doctor-txt-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>