<template>
    <div class="pc_top">
        <div class="pc_top-const">
            <div>你好，欢迎进入<strong>青羊守和堂中医院</strong>官方网站！</div>
            <div>咨询📞:18482393302</div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Pc_top',
}
</script>
<style lang="scss" scoped>
.pc_top{
    width: 100%;
    background-color: #f0f0f0;
}
.pc_top-const{
    width: 1270px;
    height: 35px;
    line-height: 35px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    strong{
        font-weight: 400;
    }
}
</style>