<template>
    <div class="form">
        <br v-if="alone">
        <M_title :title="alone ? '免费咨询预约' : '预约挂号'" linkTxt=""></M_title>
        <div class="m-form" :class="alone ? 'alone' : ''">
            <div class="form-list">
                <div class="lable">
                    <i class="el-icon-user-solid"></i>
                    姓名：
                </div>
                <!-- <div class="input">
                    <el-input size="small" v-model="formData.name" placeholder="请输入姓名"></el-input>
                </div> -->
                <input class="input" v-model="formData.name" type="text" placeholder="请输入姓名">
            </div>
            <div class="form-list">
                <div class="lable">
                    <i class="el-icon-s-custom"></i>
                    性别：
                </div>
                <el-radio v-model="formData.sex" :label="0">男</el-radio>
                <el-radio v-model="formData.sex" :label="1">女</el-radio>
            </div>
            <div class="form-list">
                <div class="lable">
                    <i class="el-icon-s-marketing"></i>
                    年龄：
                </div>
                <!-- <div class="input">
                    <el-input size="small" v-model="formData.age" placeholder="请输入年龄"></el-input>
                </div> -->
                <input v-model.number="formData.age" type="number" placeholder="请输入年龄">
            </div>
            <div class="form-list">
                <div class="lable">
                    <i class="el-icon-phone"></i>
                    电话：
                </div>
                <!-- <div class="input">
                    <el-input size="small" v-model="formData.tel" placeholder="请输入联系方式"></el-input>
                </div> -->
                <input v-model.number="formData.tel" type="number" placeholder="请输入联系方式">
            </div>
            <div class="form-list" style="align-items: flex-start;">
                <div class="lable">
                    <i class="el-icon-s-order"></i>
                    症状：
                </div>
                <textarea v-model="formData.zzms" rows="4" placeholder="请填写您的相关问题"></textarea>
            </div>
            <div class="form-btn" @click="yysubmit()">
                立即预约
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sub_from',
    data() {
        return {
            sex: 1,
            alone: false,
            formData: {
                name: undefined,
                age: undefined,
                city: undefined,
                tel: undefined,
                sex: 0,
                zzms: '',
                usermake: '',
                projectid: undefined,
            },
            yysubmitOk: true,
        };
    },
    mounted() {
        if (this.$route.path === '/from') {
            this.alone = true
        }
    },
    methods: {
        yysubmit() {
            if (!this.yysubmitOk) {
                this.$message({
                        message: '您已经提交过了',
                        type: 'error',
                        offset: 80
                    });
                return
            }
            if (!this.formData.name) {
                this.$message({
                        message: '请填入您的姓名',
                        type: 'error',
                        offset: 80
                    });
                return
            }
            if (!this.formData.tel) {
                this.$message({
                    message: '请填入您的联系方式',
                    type: 'error',
                    offset: 80
                });
                return
            }
            this.yysubmitOk = false
            this.formData.yyzj = this.$route.query?.doc || ''
            // this.formData.suburl = '抖音守和健康'
            // this.formData.suburl = '快手守和健康'
            this.formData.suburl = this.$api.suburl
            this.formData.tjtime = new Date().toISOString()
            this.formData.sourcerul = this.$api.sourcerul
            this.formData.isgt = 1

            // console.log(this.formData)

            this.$api.subLiuyan(this.formData).then(res => {
                if (res.statusCode == 200) {
                    this.$message({
                        message: '提交成功，专家会及时回复',
                        type: 'success',
                        offset: 80
                    });
                    setTimeout(() => {
                        this.yysubmitOk = true
                    }, 8000)
                } else {
                    this.yysubmitOk = true
                    this.$message.error(res.errors)
                    this.$message({
                        message: res.errors,
                        type: 'error',
                        offset: 80
                    });
                }
            }).catch(err => {
                console.log(err);
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.alone {
    height: 60vh;

    .form-btn {
        height: 50px;
    }

    .form-list {
        >input {
            height: 2.5rem;
        }
    }
}

.m-form {
    padding: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .form-list {
        display: flex;
        align-items: center;
        margin: 5px 0;
        width: 80%;

        .lable {
            color: #9c1d1d;
            font-weight: bold;
        }

        >.input {
            flex: 1;
            border: 2px solid #9c1d1d;
            border-radius: 5px;

            // height: 2rem;
            // padding: 5px;
        }
        >input {
            flex: 1;
            border: 2px solid #9c1d1d;
            border-radius: 5px;
            height: 2rem;
            padding: 5px;
        }

        >textarea {
            flex: 1;
            border: 2px solid #9c1d1d;
            border-radius: 5px;
            padding: 5px;
        }
    }

    .form-btn {
        width: 80%;
        background-color: #9c1d1d;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        color: #fff;
        margin: 0 auto;
        margin-top: 10px;
        cursor: pointer;

        &:active {
            background-color: #7c1111;
        }
    }
}
</style>