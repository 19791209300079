<template>
    <div class="views_layout" v-if="!_isMobile()">
        <div class="views_layout-img">
            <b>独树一帜博爱惠民</b>
            <img style="width: 100%;" src="http://dbysh.oss-cn-chengdu.aliyuncs.com/panda/20230828/465688839336233413.jpg" alt="">
        </div>
        <div class="views_layout-const">
            <div class="views_layout-const-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item> -->
                </el-breadcrumb>
                <div class="views_layout-const-page">
                    <router-view></router-view>
                </div>
            </div>
            <div class="views_layout-const-right">
                <Yu_yue></Yu_yue>
                <br>
                <Zixun_information :list-length="5"></Zixun_information>
            </div>
        </div>
    </div>
    <div class="views_layout" v-else>
        <router-view></router-view>
    </div>
</template>
<script>
import Zixun_information from '@/components/zixun_information'
import Yu_yue from '@/components/yuyue'
export default {
    name:'Views_layout',
    components: {
        Zixun_information,
        Yu_yue
    },
    data() {
        return {
            name: '详情',
        };
    },
    mounted(){
        this._isMobile()
    },
    watch:{
        $route:{
            deep:true,
            immediate:true,
            handler(){
                this.name = this.$route.meta?.name
            }
        }
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return window.innerWidth <= 1270 || flag;
        }
    }
}
</script>
<style lang="scss" scoped>
    
    .views_layout{
        width: 1270px;
        margin: 0 auto;
        background-color: #fff;
        overflow: hidden;
        padding: 20px;
        .views_layout-img{
            width: 1200px;
            height: 300px;
            background-color: aqua;
            margin: 0 auto;
            margin-bottom: 40px;
            overflow: hidden;
            position: relative;
            b{
                position: absolute;
                z-index: 2;
                font-size: 50px;
                font-weight: bold;
                text-align: center;
                // color: white;
                width: 1270px;
                height: 300px;
                line-height: 300px;
                letter-spacing: 0.3em;
            }
            img{
                position: absolute;
                // width: 100%;
                height: 100%;
            }
        }
        .views_layout-const{
            display: flex;
            .views_layout-const-left{
                flex: 1;
                margin-right: 10px;
                .views_layout-const-page{
                    width: 100%;
                    min-height: 900px;
                    border: 1px solid #ddd;
                    border-top: 2px solid #98181c ;
                    margin: 10px 0;
                }
            }
            .views_layout-const-right{
                width: 300px;
            }
        }
    }
</style>